import axios from 'axios';
import APIManager from './APIManager';
import querystring from 'querystring-es3';
import concatURL from '../helpers/concatURL';

export function get(params, url, action, extras = {}) {
  return APIManager.getKey().then(key => {
    return axios.request({
      url: concatURL(url),
      method: 'GET',
      headers: {
        'X-API-KEY': key,
      },
      params: params,
      ...extras,
    }).catch( err => 
      {
        console.log(err);
        return err;
      });
  });
}

export function post(params, url, action, extras = {}) {
  return APIManager.getKey().then(key => {
    return axios.request({
      url: concatURL(url),
      method: 'POST',
      headers: {
        'X-API-KEY': key,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: querystring.stringify(params),
      ...extras,
    });
  });
}

export function put(params, url, action, extras = {}) {
  return APIManager.getKey().then(key => {
    return axios.request({
      url: concatURL(url),
      method: 'PUT',
      headers: {
        'X-API-KEY': key,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: querystring.stringify(params),
      ...extras,
    });
  });
}

export function del(params, url, action, extras = {}) {
  return APIManager.getKey().then(key => {
    return axios.request({
      url: concatURL(url),
      method: 'DELETE',
      headers: {
        'X-API-KEY': key,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: querystring.stringify(params),
      ...extras,
    });
  });
}
